import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledContainer = styled.div`
  position: relative;
  width: 100%;
`;

const MainContents = ({ children }) => <StyledContainer>{children}</StyledContainer>;

MainContents.propTypes = {
  /** 필수 자식요소 */
  children: PropTypes.node.isRequired,
};
export default MainContents;
