import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Skeleton from './Skeleton';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import './lazy.css';

const Img = ({ isLoading, loader = {}, lazyLoad = false, alt, ...props }) =>
  isLoading ? (
    <Skeleton variant={loader.variant || 'rect'} width={loader.width} height={loader.height} />
  ) : (
    <LazyLoadImage effect='opacity' delayMethod='throttle' threshold={0} visibleByDefault={!lazyLoad} {...props} />
  );

Img.propTypes = {
  /** Loading 화면 표시 */
  isLoading: PropTypes.bool,
  /** Loader 설정 */
  loader: PropTypes.shape({
    variant: PropTypes.oneOf(['text', 'rectangular', 'rounded', 'circular']).isRequired,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  /** Image가 처음부터 표시되지 않아도 되는지에 대한 값 */
  lazyLoad: PropTypes.bool,
  /** Image tag의 alt attribute */
  alt: PropTypes.string,
};

export default Img;
