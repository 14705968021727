import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1;
  ${({ platform }) =>
    platform === 'android' &&
    `
    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background: #aaa;
      border-radius: 9px;
    }
    &.scroll-hide::-webkit-scrollbar {
      display: none;
    }
    `}
`;

const ScrollableContainer = ({ className, children }) => {
  const containerRef = useRef(null);

  const changeScrollHide = (element, scrollClass, type) => {
    const classNames = (element.className && element.className.split(' ')) || [];
    const newClassNames = classNames.filter((targetClassName) => targetClassName !== scrollClass);

    if (type === 'add') {
      newClassNames.push(scrollClass);
    }
    element.className = newClassNames.join(' ').trim();
  };

  const setScrollEvent = (element) => {
    // if (Environment.platform === 'android') {
    element.style['overflow-y'] = 'overlay';
    element.style['-webkit-overflow-scrolling'] = 'touch';
    changeScrollHide(element, 'scroll-show', 'add');
    changeScrollHide(element, 'scroll-hide', 'add');
    const scrollHandler = (e) => {
      if (window.scrollEventCallback) {
        window.scrollEventCallback(e);
      }
      clearTimeout(element.scrollTimeout);
      changeScrollHide(element, 'scroll-hide', 'remove');
      element.scrollTimeout = setTimeout(() => {
        changeScrollHide(element, 'scroll-hide', 'add');
      }, 500);
    };
    element.addEventListener('scroll', scrollHandler);
    // }
  };

  useEffect(() => {
    if (containerRef && containerRef.current) {
      setScrollEvent(containerRef.current);
    }
  }, [containerRef]);

  return (
    <Container className={`${className} scrollable`} ref={containerRef}>
      {children}
    </Container>
  );
};
ScrollableContainer.propTypes = {
  /** 부모 컴포넌트가 전달한 style */
  className: PropTypes.string,
  /** 부모 컴포넌트가 전달한 컨테이너 구성 요소 */
  children: PropTypes.node.isRequired,
};
export default ScrollableContainer;
