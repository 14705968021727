import PropTypes from 'prop-types';
import Skeleton from '@mui/material/Skeleton';

const AtomSkeleton = ({ variant, width, height }) => <Skeleton variant={variant} width={width} height={height} />;

AtomSkeleton.propTypes = {
  variant: PropTypes.oneOf(['text', 'rectangular', 'rounded', 'circular']).isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default AtomSkeleton;
